<template>
  <aside
    v-if="survey"
    class="sidebar"
    :class="{ 'sidebar-collapsed': !navIsOpen }"
  >
    <logo v-show="navIsOpen" color="white" size="sm" />

    <template v-for="tab in tabs">
      <router-link
        v-if="
          tab.viewAlways
            ? true
            : tab.viewAfterActivation
            ? survey.active
            : !survey.active
        "
        :key="tab.path"
        :to="`/${tab.path}`"
        class="sidebar__link"
        exact-active-class="sidebar__link--active"
        :active-class="tab.text !== 'Inicio' ? 'sidebar__link--active' : ''"
      >
        <font-awesome-icon :icon="tab.icon" fixed-width size="lg" />
        <span>{{ tab.text }}</span>
      </router-link>
    </template>

    <button class="nav-toggler" @click="toggleNav">
      <font-awesome-icon
        :icon="navIsOpen ? icons.chevronLeft : icons.chevronRight"
        slot="icon"
        size="lg"
      />
    </button>
  </aside>
</template>

<script>
import {
  faLayerGroup,
  faStickyNote,
  faChartBar,
  faUsers,
  faList,
  faHome,
  faCalendarAlt,
  faCog,
  faChevronLeft,
  faChevronRight,
  faClipboardCheck,
  faPlus
} from "@fortawesome/free-solid-svg-icons";

import { mapMutations, mapState } from "vuex";

import Logo from "./Logo.vue";

export default {
  name: "Sidebar",

  components: { Logo },

  data: () => ({
    tabs: [
      {
        text: "Inicio",
        icon: faHome,
        path: "",
        viewAlways: true
      },
      {
        text: "Dimensiones",
        icon: faLayerGroup,
        path: "dimensions"
      },
      {
        text: "Escalas",
        icon: faList,
        path: "scale"
      },
      {
        text: "Comunicados",
        icon: faStickyNote,
        path: "releases"
      },
      {
        text: "Usuarios",
        icon: faUsers,
        path: "users",
        viewAlways: true
      },
      {
        text: "Dashboard",
        icon: faChartBar,
        path: "dashboard",
        viewAfterActivation: true
      },
      {
        text: "Periodos",
        icon: faCalendarAlt,
        path: "periods",
        viewAfterActivation: true
      },
      {
        text: "Respuestas",
        icon: faPlus,
        path: "written-answers",
        viewAfterActivation: true
      },
      {
        text: "Planes",
        icon: faClipboardCheck,
        path: "action-plans",
        viewAfterActivation: true
      },
      {
        text: "Ajustes",
        icon: faCog,
        path: "settings",
        viewAlways: true
      }
    ],

    icons: {
      chevronLeft: faChevronLeft,
      chevronRight: faChevronRight
    }
  }),

  computed: {
    ...mapState(["survey", "navIsOpen"])
  },

  methods: {
    ...mapMutations(["toggleNav"])
  }
};
</script>

<style scoped>
.sidebar {
  flex: 0 0 200px;
  display: flex;
  flex-direction: column;
  padding: var(--lengthMd2) var(--lengthSm2);
  background: rgb(21, 177, 149);
  background: linear-gradient(
    180deg,
    rgba(21, 177, 149, 1) 50%,
    rgba(72, 218, 175, 1) 100%
  );
}

.sidebar__link {
  display: flex;
  align-items: center;
  grid-gap: var(--lengthSm2);
  gap: var(--lengthSm2);
  margin-top: var(--lengthSm2);
  padding: var(--lengthMd1) var(--lengthMd2);
  color: white;
  font-weight: var(--semi-bold);
  border-radius: var(--lengthSm1);
}

.sidebar__link:hover,
.sidebar__link--active {
  background-color: rgba(255, 255, 255, 0.25);
}

.nav-toggler {
  align-self: flex-end;
  margin-top: auto;
  padding: var(--lengthSm2) var(--lengthSm3);
  color: white;
  background-color: rgba(255, 255, 255, 0.25);
  border-radius: var(--lengthSm1);
}

.sidebar-collapsed {
  flex: 0 0 auto;
}

.sidebar-collapsed .nav-toggler {
  align-self: center;
}

.sidebar-collapsed .sidebar__link span {
  display: none;
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: var(--lengthMd2);
}
</style>
